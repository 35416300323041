.qualification {
  margin-bottom: 10rem;
}
.qualification__titles {
  text-align: center;
  margin-bottom: 2rem;
}
.qualification__container {
  max-width: 768px;
}

.qualification__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 var(--mb-3);
  cursor: pointer;
}

.qualification__button:hover {
  color: var(--title-color-dark);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.qualification__active {
  color: var(--title-color-dark);
}

.qualification__sections {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
}

.qualification__content {
  display: none;
}

.qualification__content-active {
  display: block;
}

.qualification__data {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1.5rem;
}

.qualification__2nd {
  margin-left: 10.5rem;
}

.qualification__2nd-front {
  margin-right: 30px;
}

.qualification__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--smaller-font-size);
}

.qualification__calendar {
  font-size: var(--small-font-size);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--text-color);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--text-color);
  transform: translate(6px, -7px);
}

@media screen and (max-width: 992px) {
  .qualification__2nd {
    margin-left: 9.8rem;
  }

  .qualification__2nd-front {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
}
@media screen and (max-width: 350px) {
}
